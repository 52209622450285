import List from './list';
import Create from './create';
import Edit from './edit';

import { ListGuesser, EditGuesser, ShowGuesser } from 'react-admin';

export default { 
    options: { label: 'Private Event Timeslot' },
    create: Create, 
    list: List, 
    edit: Edit,
}