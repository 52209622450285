import * as React from "react";
import { required, Create, SimpleForm, ReferenceInput, SelectInput, NumberInput, DateTimeInput, TextInput, DateInput, BooleanInput } from 'react-admin';
import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';

const Title = () => {
    return <span>Create Private Event Timeslot</span>;
};

const useStyles = makeStyles((theme) => ({
    root: {
      flexGrow: 1,
    }
  }));

const CustomForm = () => {
    const classes = useStyles();
    return (
    <div className={classes.root}>
        <Grid container style={{width:"50%"}}>
            <Grid item xs={10}>
                <ReferenceInput source="group_id" reference="private-event-timeslot-group" label="Event ID" validate={[required()]} fullWidth>
                    <SelectInput optionText="event_id" />
                </ReferenceInput>
            </Grid>
            <Grid item xs={10}>
                <TextInput source="title" validate={[required()]} fullWidth/>
            </Grid>
            <Grid item xs={10}>
                <TextInput source="description" fullWidth/>
            </Grid>
            <Grid item xs={10}>
                <NumberInput source="ticket_limit" fullWidth/>
            </Grid>
            <Grid item xs={6}>
                <DateTimeInput source="start_time" parse={v => String(v)} validate={[required()]}/>
            </Grid>
            <Grid item xs={6}>
                <DateTimeInput source="end_time" parse={v => String(v)} validate={[required()]}/>
            </Grid>
            <Grid item xs={10}>
                <TextInput source="available_grade" fullWidth/>
            </Grid>
        </Grid>
    </div>
    )};

const CustomCreate = (props) => (
    <Create title={<Title />} {...props}>
        <SimpleForm>
            <CustomForm />
        </SimpleForm>
    </Create>
);

export default CustomCreate;