import * as React from "react";
import { List, Show, SimpleShowLayout, Datagrid, TextField, Filter, TextInput, BooleanField, ReferenceField, ReferenceInput, SelectInput, BulkDeleteButton } from 'react-admin';

const CustomFilter = (props) => (
    <Filter {...props}>
        <TextInput label="Email" source="email" alwaysOn />
        <ReferenceInput label="Corporate" source="corporate_id" reference="corporates" perPage={999} allowEmpty alwaysOn>
            <SelectInput optionText="name"/>
        </ReferenceInput>
        <TextInput label="City" source="city" alwaysOn/>
        <TextInput label="Customer ID" source="customer_id"/>        
        <TextInput label="Remark" source="remark"/>
    </Filter>
);

const CustomShow = props => (
    <Show
        {...props}
        /* disable the app title change when shown */
        title=" "
    >
        <SimpleShowLayout>
            <TextField source="customer_id" label="Customer ID"/>
            <TextField source="gender" />
            <TextField source="phone" />
            <TextField source="phone_country_code" />
            <TextField source="address_one" />
            <TextField source="user_type" />
            <TextField source="bash" />
            <TextField source="subscribed_status" />
            <TextField source="shopify_status" />
            <TextField source="regsitered_via" />
            <TextField source="vip" />
            <TextField source="accept_terms" />
            <TextField source="klaviyo_hk" />
            <TextField source="klaviyo_SG" label="Klaviyo sg" />
            <TextField source="klaviyo_TW" label="Klaviyo tw"/>
            <BooleanField source="sync" />
        </SimpleShowLayout>
    </Show>
);

const MyBulkActionButtons = props => (
    <BulkDeleteButton 
        undoable={false} 
        confirmTitle={'Delete User'} 
        confirmContent={'Are you sure you want to delete these items?'} 
        {...props} 
    />
);  

const CustomList = props => (
    <List filters={<CustomFilter />} bulkActionButtons={<MyBulkActionButtons />} exporter={false} {...props} title="User" perPage={25} sort={{ field: 'id', order: 'DESC' }}>
        <Datagrid rowClick="edit" expand={<CustomShow />}>
            <TextField source="email" />
            <TextField source="first_name" />
            <TextField source="last_name" />
            <TextField source="dob" />
            <TextField source="city" />
            <TextField source="remark" />
            <ReferenceField source="corporate_id" reference="corporates">
                <TextField source="name" />
            </ReferenceField>            
        </Datagrid>
    </List>
);

export default CustomList;