import * as React from "react";
import { required, Edit, SimpleForm, TextInput, BooleanInput, ReferenceArrayInput, SelectArrayInput, ReferenceInput, SelectInput } from 'react-admin';
import { CustomToolbar } from '../layout';
import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';

const Title = ({ record }) => {
    return <span>Private Event Timeslot Group {record ? `#${record.id}` : ''}</span>;
};

const useStyles = makeStyles((theme) => ({
    root: {
      flexGrow: 1,
    }
  }));

const CustomForm = () => {
    const classes = useStyles();
    return (
    <div className={classes.root}>
        <Grid container style={{width:"33.3%"}}>
            <Grid item xs={12}>
            <ReferenceInput source="event_id" reference="private-event" validate={[required()]} fullWidth disabled>
                <SelectInput optionText="name" />
            </ReferenceInput>
            </Grid>
            <Grid item xs={12}>
                <TextInput source="title" validate={[required()]} fullWidth/>
            </Grid>
            <Grid item xs={12}>
                <TextInput source="available_grade" fullWidth/>
            </Grid>
            <Grid item xs={12}>
                <BooleanInput source="reserve_limit"/>
            </Grid>
        </Grid>
    </div>
)};

const CustomEdit = (props) => (
    <Edit title={<Title />} {...props}>
        <SimpleForm toolbar={<CustomToolbar />} warnWhenUnsavedChanges>
            <CustomForm />
        </SimpleForm>
    </Edit>
);

export default CustomEdit;