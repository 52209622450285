import * as React from "react";
import { Create, SimpleForm, TextInput, required, ReferenceInput, SelectInput } from 'react-admin';

const CustomCreate = props => (
    <Create {...props}>
        <SimpleForm>
            <TextInput source="guest_email" validate={[required()]}/>
            <TextInput source="receiver_email" validate={[required()]}/>
            <ReferenceInput label="Private Event" source="private_event_id" reference="private-event" allowEmpty>
                <SelectInput optionText="name" />
            </ReferenceInput>
            <TextInput source="receiver_phone" />
            <TextInput source="receiver_slack" />
        </SimpleForm>
    </Create>
);

export default CustomCreate;