import * as React from "react";
import { required, Create, SimpleForm, ReferenceInput, SelectInput, TextInput, DateInput, DateTimeInput, BooleanInput } from 'react-admin';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';

const Title = () => {
    return <span>Create Private Event</span>;
};

const useStyles = makeStyles((theme) => ({
    root: {
      flexGrow: 1,
    }
  }));

function genRandom(length) {
    var result           = '';
    var characters       = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    var charactersLength = characters.length;
    for ( var i = 0; i < length; i++ ) {
       result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }
    return result;
}

const CustomForm = () => {
    const classes = useStyles();
    return (
    <div className={classes.root}>
        <Grid container style={{width:"40%"}} spacing={1}>
            <Grid item xs={6}>
                <TextInput source="name" validate={[required()]} fullWidth/>
            </Grid>
            <Grid item xs={6}>
                <TextInput source="brand" validate={[required()]} fullWidth/>
            </Grid>
            <Grid item xs={12}>
                <TextInput source="logo_img" multiline fullWidth/>
            </Grid>
            <Grid item xs={6}>
                <DateInput source="start_date" validate={[required()]} fullWidth/>
            </Grid>
            <Grid item xs={6}>
                <DateInput source="end_date" validate={[required()]} fullWidth/>
            </Grid>
            <Grid item xs={12}>
                <DateTimeInput source="staff_end_date" parse={v => String(v)} validate={[required()]} fullWidth/>
            </Grid>
            <Grid item xs={6}>
                <TextInput source="default_lang" fullWidth/>
            </Grid>
            <Grid item xs={6}>
                <SelectInput source="sales_channel" fullWidth choices={[
                    { id: 'ONLINE', name: 'ONLINE' },
                    { id: 'OFFLINE', name: 'OFFLINE' }
                ]} />
            </Grid>
            <Grid item xs={12}>
                <TextInput source="description" multiline fullWidth/>
            </Grid>
            <Grid item xs={12}>        
                <ReferenceInput label="Location" source="location" reference="location" fullWidth validate={[required()]}>
                    <SelectInput optionText="name" />
                </ReferenceInput>
            </Grid>
            <Grid item xs={6}>
                <TextInput source="city" fullWidth/>
            </Grid>
            <Grid item xs={6}>
                <TextInput source="policy" fullWidth/>
            </Grid>
            <Grid item xs={6}>
                <TextInput source="from_email" fullWidth/>
            </Grid>
            <Grid item xs={6}>
                <TextInput source="from_name" fullWidth/>
            </Grid>
            <Grid item xs={6}>
                <TextInput source="phone_no_code" fullWidth/>
            </Grid>
            <Grid item xs={6}>
                <TextInput source="public_guest_parent" fullWidth/>
            </Grid>
            <TextInput source="public_guest_allowed_domain" multiline fullWidth/>
            <TextInput source="public_guest_description" multiline fullWidth/>
            <TextInput source="public_guest_passcode" initialValue={genRandom(32)} disabled fullWidth/>
            <TextInput source="dashboard_url" fullWidth/>
            <TextInput source="quicksight_id" label="Quicksight ID" fullWidth/>
            <TextInput source="dashboard_secret" fullWidth/>
            <Grid item xs={12}>
                <BooleanInput label="Public Guest" source="public_guest" />
                <BooleanInput label="Show Phone No" source="public_guest_show_phoneno" />
                <BooleanInput label="Registration page show logo" source="registration_page_show_logo" />
            </Grid>
        </Grid>
    </div>
)};

const CustomCreate = props => (
    <Create title={<Title />} {...props}>
        <SimpleForm>
            <CustomForm />
        </SimpleForm>
    </Create>
);

export default CustomCreate;