import * as React from "react";
import { Edit, SimpleForm, TextInput, required, ReferenceInput, SelectInput } from 'react-admin';
import { CustomToolbar } from '../layout';

const Title = ({ record }) => {
    return <span>VIP Notification {record ? `#${record.id}` : ''}</span>;
};


const CustomEdit = (props) => (
    <Edit title={<Title />} undoable={false} {...props}>
        <SimpleForm toolbar={ <CustomToolbar /> } warnWhenUnsavedChanges>
            <TextInput source="guest_email" type="email" validate={[required()]}/>
            <TextInput source="receiver_email" type="email" validate={[required()]}/>
            <ReferenceInput label="Private Event" source="private_event_id" reference="private-event" allowEmpty>
                <SelectInput optionText="name" />
            </ReferenceInput>
            <TextInput source="receiver_phone" />
            <TextInput source="receiver_slack" />
        </SimpleForm>
    </Edit>
);

export default CustomEdit;