import * as React from "react";
import { List, Datagrid, TextField, EmailField, NumberField, DateField, ReferenceField, Filter, ReferenceInput, SelectInput, TextInput, CardActions, CreateButton  } from 'react-admin';
import { ListActions } from '../layout';

const CustomFilter = (props) => (
    <Filter {...props}>
        <TextInput label="Guest Email" source="guest_email" alwaysOn />
        {/* <ReferenceInput label="User" source="userId" reference="users" allowEmpty>
            <SelectInput optionText="name" />
        </ReferenceInput> */}
    </Filter>
);

const CustomList = props => (
    <List filters={<CustomFilter />} bulkActionButtons={false} exporter={false} {...props} title="VIP Notification" perPage={25} sort={{ field: 'id', order: 'DESC' }}>
        <Datagrid rowClick="edit">
            <TextField source="id"/>
            <TextField source="guest_email" />
            <ReferenceField source="private_event_id" reference="private-event"><TextField source="name" /></ReferenceField>
            <TextField source="receiver_email" />
            <TextField source="receiver_phone" />
            <TextField source="receiver_slack" />
            <DateField source="create_date" />
        </Datagrid>
    </List>
);

export default CustomList;