import * as React from "react";
import { List, Datagrid, TextField, BooleanField, DateField, Filter, ReferenceField, ReferenceInput, SelectInput, TextInput  } from 'react-admin';
import { ListActions } from '../layout';

const CustomFilter = (props) => (
    <Filter {...props}>
        {/* <TextInput label="Search" source="q" alwaysOn /> */}
        <ReferenceInput label="Event" source="event_id" reference="private-event" perPage={50} alwaysOn>
            <SelectInput optionText="name" />
        </ReferenceInput>
    </Filter>
);

const CustomList = props => (
    <List {...props} filters={<CustomFilter />} bulkActionButtons={false} exporter={false} title="Private Event Timeslot Group" perPage={25} sort={{ field: 'id', order: 'DESC' }}>
        <Datagrid rowClick="edit">
            <TextField source="id" />
            <ReferenceField source="event_id" reference="private-event"><TextField source="name" /></ReferenceField>
            <TextField source="title" />
            <TextField source="available_grade" />
            <BooleanField source="reserve_limit" />
            <DateField source="create_date" showTime />
        </Datagrid>
    </List>

);

export default CustomList;