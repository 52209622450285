import EventIcon from '@material-ui/icons/Event';
import List from './list';
import Create from './create';
import Edit from './edit';

export default { 
    options: { label: 'Private Event Limit' },
    create: Create, 
    list: List, 
    edit: Edit,
    icon: EventIcon
}