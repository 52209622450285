import * as React from "react";
import { List, Datagrid, TextField, Filter, TextInput, ImageField, DateField, NumberField, SimpleShowLayout, Show, ReferenceField, ReferenceInput, SelectInput } from 'react-admin';

const CustomFilter = (props) => (
    <Filter {...props}>
        <TextInput label="Corporate Name" source="name" alwaysOn />
        <ReferenceInput label="City" source="city" reference="city" perPage={50} alwaysOn>
            <SelectInput optionText="name" />
        </ReferenceInput>
    </Filter>
);

const CustomShow = props => (
    <Show
        {...props}
        /* disable the app title change when shown */
        title=" "
    >
        <SimpleShowLayout>
            <ImageField source="logo" />
            <TextField source="hash" />
            {/* <DateField source="premium_expiration_date" />
            <NumberField source="premium_duration_month" />
            <DateField source="expiry_date" /> */}
        </SimpleShowLayout>
    </Show>
);

const CustomList = props => (
    <List filters={<CustomFilter />} bulkActionButtons={false} exporter={false} {...props} title="Corporates" perPage={25} sort={{ field: 'id', order: 'DESC' }}>
        <Datagrid rowClick="edit" expand={<CustomShow />}>
            <TextField source="id"/>
            <TextField source="name" />
            <TextField source="type" />
            <ReferenceField source="city" reference="city">
                <TextField source="name" />
            </ReferenceField>
            <TextField source="domains" />
            <TextField source="registration_points" />
        </Datagrid>
    </List>
);

export default CustomList;