import * as React from "react";
import { required, Create, SimpleForm, ReferenceInput, SelectInput, NumberInput, TextInput, DateTimeInput, ReferenceField,  BooleanInput } from 'react-admin';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';

const Title = () => {
    return <span>Create Private Event Limit </span>;
};

const useStyles = makeStyles((theme) => ({
    root: {
      flexGrow: 1,
    }
  }));

function genRandom(length) {
    var result           = '';
    var characters       = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    var charactersLength = characters.length;
    for ( var i = 0; i < length; i++ ) {
       result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }
    return result;
}

const handelize = v => {
    return v.toLowerCase().replace(/[^a-z0-9]+/g, '-').replace(/-$/, '').replace(/^-/, '');
}

const CustomForm = () => {
    const classes = useStyles();
    return (
    <div className={classes.root}>
        <Grid container style={{width:"50%"}} spacing={1}>
            <Grid item xs={12}>
                <TextInput source="title" fullWidth/>
            </Grid>
            <Grid item xs={6}>
                <ReferenceInput source="event_id" reference="private-event" validate={[required()]} fullWidth>
                    <SelectInput optionText="name" />
                </ReferenceInput>
            </Grid>
            <Grid item xs={6}>
                <ReferenceInput source="default_timeslot_id" reference="private-event-timeslot" perPage={999} fullWidth AllowEmpty>
                    <SelectInput optionText="title"/>
                </ReferenceInput>
            </Grid>
            <Grid item xs={4}>
                <TextInput source="staff_type" />
            </Grid>
            <Grid item xs={4}>
                <TextInput source="child_staff_type" />
            </Grid>
            <Grid item xs={4}>
                <NumberInput source="default_child_no" initialValue="0" />
            </Grid>
            <Grid item xs={4}>
                <TextInput source="flashsale_title" initialValue="Invitation" />
            </Grid>
            <Grid item xs={4}>
                <TextInput source="policy_page_title" />
            </Grid>
            <Grid item xs={4}>
                <TextInput source="flyer_url" />
            </Grid>
            <Grid item xs={6}>
                <TextInput source="sms_timeslot_template" fullWidth/>
            </Grid>
            <Grid item xs={6}>
                <TextInput source="sms_invitation_template" initialValue="invitation" fullWidth/>
            </Grid>
            <Grid item xs={6}>
                <TextInput source="invitation_subject" fullWidth/>
            </Grid>
            <Grid item xs={6}>
                <TextInput source="invitation_template" parse={handelize} helperText="paste mailchimp template name, it will parse to mandrill format" fullWidth/>
            </Grid>
            <Grid item xs={4}>
                <NumberInput source="invitation_limit" />
            </Grid>
            <Grid item xs={4}>
                <TextInput source="invitation_staff_type" />
            </Grid>
            <Grid item xs={4}>
                <TextInput source="invitation_merge_tags" />
            </Grid>
            <TextInput source="invitation_html" multiline fullWidth />
            <Grid item xs={6}>
                <TextInput source="shop_now_subject" fullWidth />
            </Grid>
            <Grid item xs={3}>
                <NumberInput source="max_login_device" initialValue="0"/>
            </Grid>
            <Grid item xs={3}>
                <NumberInput source="utc_timezone"  />
            </Grid>            
            <Grid item xs={6}>
                <TextInput source="registered_template" fullWidth/>
            </Grid>
            <Grid item xs={6}>
                <DateTimeInput source="timeslot_open_time" parse={v => String(v)} fullWidth /> 
            </Grid>
            <Grid item xs={6}>
                <TextInput source="timeslot_subject" fullWidth/>
            </Grid>
            <Grid item xs={6}>
                <TextInput source="timeslot_template" parse={handelize} helperText="paste mailchimp template name, it will parse to mandrill format" fullWidth/>
            </Grid>
            <TextInput source="timeslot_html" multiline fullWidth />
            <TextInput source="policy" multiline fullWidth />
            <TextInput source="share_form_desc" multiline fullWidth/>
            <TextInput source="staff_type_key" initialValue={genRandom(64)} disabled fullWidth/>
            <Grid item xs={4}>
                <TextInput source="landing_page" initialValue="main" />
            </Grid>
            <Grid item xs={4}>
                <TextInput source="checkin_type" initialValue="EVENT" />
            </Grid>
            <Grid item xs={4}>
                <NumberInput source="checkin_limit" initialValue="999" />
            </Grid>
            <Grid item xs={4}>
                <TextInput source="link_with_public_desc" />
            </Grid>
            <Grid item xs={4}>
                <SelectInput source="invitation_method" initialValue="FORM" fullWidth choices={[
                    { id: 'FORM', name: 'FORM' },
                    { id: 'LINK', name: 'LINK' },
                    { id: 'MIXED', name: 'MIXED' },
                ]} />
            </Grid>            
            <Grid item xs={4}>
                <TextInput source="guest_overwrite_level" />
            </Grid>
            <TextInput source="allowed_domain" fullWidth/>
            <Grid item xs={6}>
                <TextInput source="custom_field_01" multiline fullWidth/>
            </Grid>
            <Grid item xs={6}>
                <TextInput source="guest_custom_field_01_label" fullWidth/>
            </Grid>
            <Grid item xs={6}>
                <TextInput source="custom_field_02" multiline fullWidth/>
            </Grid>
            <Grid item xs={6}>
                <TextInput source="guest_custom_field_02_label" fullWidth/>
            </Grid>
            <Grid item xs={6}>
                <TextInput source="custom_field_03" multiline fullWidth/>
            </Grid>
            <Grid item xs={6}>
                <TextInput source="guest_custom_field_03_label" fullWidth/>
            </Grid>
            <Grid item xs={6}>
                <TextInput source="custom_field_04" multiline fullWidth/>
            </Grid>
            <Grid item xs={6}>
                <TextInput source="guest_custom_field_04_label" fullWidth/>
            </Grid>
            <Grid item xs={6}>
                <TextInput source="custom_field_05" multiline fullWidth/>
            </Grid>
            <Grid item xs={6}>
                <TextInput source="guest_custom_field_05_label" fullWidth/>
            </Grid>
            <BooleanInput source="require_otl_membership" />
            <BooleanInput source="show_phone_input" />
            <BooleanInput source="show_registration_form" />
            <BooleanInput source="show_timeslots" />
            <BooleanInput source="show_invitation" />
            <BooleanInput source="is_admin_choice" />
            <BooleanInput source="accepted_guest_only" />
            <BooleanInput source="link_with_public" />
            <BooleanInput source="enable_two_factor" />
        </Grid>
    </div>
)};

const CustomCreate = props => (<Create title={<Title />} {...props}>
        <SimpleForm>
            <CustomForm />
        </SimpleForm>
    </Create>
);

export default CustomCreate;