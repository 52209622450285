import * as React from "react";
import { List, Datagrid, TextField, NumberField, DateField, Filter, ReferenceField, ReferenceInput, SelectInput, TextInput, FormDataConsumer } from 'react-admin';

const CustomFilter = (props) => (
    <Filter {...props}>
        {/* <TextInput label="Search" source="q" alwaysOn /> */}
        <ReferenceInput label="Event ID" source="group_id" reference="private-event-timeslot-group" alwaysOn>
            <SelectInput optionText="event_id" />
        </ReferenceInput>
    </Filter>
);

const CustomList = props => (
    <List {...props} filters={<CustomFilter />} bulkActionButtons={false} exporter={false} title="Private Event Timeslot" perPage={25} sort={{ field: 'id', order: 'DESC' }}>
        <Datagrid rowClick="edit">
            <ReferenceField source="group_id" reference="private-event-timeslot-group"><TextField source="id" /></ReferenceField>
            <TextField source="title" />
            <TextField source="description" />
            <NumberField source="ticket_limit" />
            <DateField source="start_time" showTime/>
            <DateField source="end_time" showTime/>
            <TextField source="available_grade" />
            <DateField source="create_time" />
        </Datagrid>
    </List>

);

export default CustomList;