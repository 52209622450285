import * as React from "react";
import { List, Show, SimpleShowLayout, Datagrid, TextField, ImageField, RichTextField, UrlField, DateField, BooleanField, Filter, ReferenceInput, ReferenceField, SelectInput, TextInput  } from 'react-admin';
import { ListActions } from '../layout';

const CustomFilter = (props) => (
    <Filter {...props}>
        <TextInput label="Name" source="name" alwaysOn />
        <TextInput label="Brand" source="brand" alwaysOn />
        <ReferenceInput label="Location" source="location" reference="location" allowEmpty alwaysOn>
            <SelectInput optionText="name" />
        </ReferenceInput>
    </Filter>
);

const CustomShow = props => (
    <Show
        {...props}
        /* disable the app title change when shown */
        title=" "
    >
        <SimpleShowLayout>
            <ImageField source="logo_img" />
            <TextField source="description" />
            <TextField source="public_guest_allowed_domain" />
            <RichTextField source="public_guest_description" multiline/>
            <TextField source="public_guest_passcode" />
            <TextField source="from_email" />
            <TextField source="from_name" />
            <RichTextField source="policy" />
            <UrlField source="dashboard_url" />
            <TextField source="quicksight_id" />
            <TextField source="dashboard_secret" />
            <BooleanField source="registration_page_show_logo" />
            <BooleanField source="public_guest_show_phoneno" label="Show Phone No"/>
        </SimpleShowLayout>
    </Show>
);

const CustomList = props => (
    <List {...props} filters={<CustomFilter />} bulkActionButtons={false} exporter={false} title="Private Event" perPage={25} sort={{ field: 'id', order: 'DESC' }}>
        <Datagrid rowClick="edit" expand={<CustomShow />}>
            <TextField source="id" />
            <TextField source="name" />
            <TextField source="default_lang" />
            <TextField source="sales_channel" />
            <TextField source="brand" />
            <DateField source="start_date" />
            <DateField source="end_date" />
            <DateField source="staff_end_date" showTime/>
            <ReferenceField source="location" reference="location"><TextField source="name" /></ReferenceField>
            <TextField source="city" />
            <TextField source="phone_no_code" />
            <BooleanField source="public_guest" />
        </Datagrid>
    </List>
);

export default CustomList;