import * as React from "react";
import { required, Edit, SimpleForm, TextInput, DateTimeInput, SelectInput, NumberInput, ReferenceInput, BooleanInput } from 'react-admin';
import { CustomToolbar } from '../layout';
import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';

const Title = ({ record }) => {
    return <span>Private Event Timeslot {record ? `#${record.id}` : ''}</span>;
};

const useStyles = makeStyles((theme) => ({
    root: {
      flexGrow: 1,
    }
  }));

const CustomForm = () => {
    const classes = useStyles();
    return (
    <div className={classes.root}>
        <Grid container style={{width:"50%"}}>
            <Grid item xs={10}>
                <ReferenceInput source="group_id" reference="private-event-timeslot-group" label="Event ID" validate={[required()]} fullWidth>
                    <SelectInput optionText="event_id" />
                </ReferenceInput>
            </Grid>
            <Grid item xs={10}>
                <TextInput source="title" validate={[required()]} fullWidth/>
            </Grid>
            <Grid item xs={10}>
                <TextInput source="description" fullWidth/>
            </Grid>
            <Grid item xs={10}>
                <NumberInput source="ticket_limit" fullWidth/>
            </Grid>
            <Grid item xs={6}>
                <DateTimeInput source="start_time" parse={v => String(v)} validate={[required()]}/>
            </Grid>
            <Grid item xs={6}>
                <DateTimeInput source="end_time" parse={v => String(v)} className={classes.end_time} validate={[required()]}/>
            </Grid>
            <Grid item xs={10}>
                <TextInput source="available_grade" fullWidth/>
            </Grid>
        </Grid>    
    </div>
    )};

const CustomEdit = (props) => (
    <Edit title={<Title />} {...props} >
        <SimpleForm toolbar={<CustomToolbar />} warnWhenUnsavedChanges>
            <CustomForm />
        </SimpleForm>
    </Edit>
);

export default CustomEdit;