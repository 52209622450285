import NotificationsIcon from '@material-ui/icons/Notifications';
import List from './list';
import Create from './create';
import Edit from './edit';

export default { 
    options: { label: 'VIP Notification' },
    icon: NotificationsIcon,
    create: Create,
    list: List,
    edit: Edit 
}