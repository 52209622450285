import * as React from "react";
import { Create, SimpleForm, TextInput, NumberInput, required, ReferenceInput, SelectInput, Date } from 'react-admin';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';

const Title = () => {
    return <span>Create Corporates </span>;
};

const useStyles = makeStyles((theme) => ({
    root: {
      flexGrow: 1,
    }
  }));

function genRandom(length) {
    var result           = '';
    var characters       = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    var charactersLength = characters.length;
    for ( var i = 0; i < length; i++ ) {
       result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }
    return result;
}

const CustomForm = () => {
    const classes = useStyles();
    return (
    <div className={classes.root}>
        <Grid container style={{width:"40%"}} spacing={1}>
            <Grid item xs={6}>
                <TextInput source="name" validate={[required()]} fullWidth/>
            </Grid>
            <Grid item xs={6}>
                <TextInput source="type" fullWidth/>
            </Grid>
            <Grid item xs={6}>
                <ReferenceInput label="City" source="city" reference="city" fullWidth>
                    <SelectInput optionText="name" />
                </ReferenceInput>
            </Grid>         
            <Grid item xs={6}>
                <NumberInput source="registration_points" initialValue="2000" fullWidth/>
            </Grid>
            <TextInput source="domains" fullWidth/>
            <TextInput source="logo" fullWidth />   
            <TextInput source="hash" initialValue={genRandom(32)} fullWidth disabled />
        </Grid>
    </div>
)};

const CustomCreate = props => (<Create title={<Title />} {...props}>
        <SimpleForm>
            <CustomForm />
        </SimpleForm>
    </Create>
);


export default CustomCreate;