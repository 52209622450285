import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import SettingsIcon from '@material-ui/icons/Settings';
import LabelIcon from '@material-ui/icons/Label';
import { useMediaQuery } from '@material-ui/core';
import { useTranslate, DashboardMenuItem, MenuItemLink } from 'react-admin';

import privateEvent from '../privateEvent';
import vip from '../vip';
import SubMenu from './SubMenu';

const Menu = ({ onMenuClick, dense, logout }) => {
    const [state, setState] = useState({
        menuCatalog: false,
        menuSales: false,
        menuCustomers: false,
        menuPrivateEvent: false,
        menuPublicEvent: false
    });
    const translate = useTranslate();
    const isXsmall = useMediaQuery(theme => theme.breakpoints.down('xs'));
    const open = useSelector(state => state.admin.ui.sidebarOpen);
    useSelector(state => state.theme); // force rerender on theme change

    const handleToggle = menu => {
        setState(state => ({ ...state, [menu]: !state[menu] }));
    };

    return (
        <div>
            {' '}
            {/* <DashboardMenuItem onClick={onMenuClick} sidebarIsOpen={open} /> */}
            {/* <SubMenu
                handleToggle={() => handleToggle('menuSales')}
                isOpen={state.menuSales}
                sidebarIsOpen={open}
                name="pos.menu.sales"
                icon={<orders.icon />}
                dense={dense}
            >
                <MenuItemLink
                    to={`/commands`}
                    primaryText={translate(`resources.commands.name`, {
                        smart_count: 2,
                    })}
                    leftIcon={<orders.icon />}
                    onClick={onMenuClick}
                    sidebarIsOpen={open}
                    dense={dense}
                />
                <MenuItemLink
                    to={`/invoices`}
                    primaryText={translate(`resources.invoices.name`, {
                        smart_count: 2,
                    })}
                    leftIcon={<invoices.icon />}
                    onClick={onMenuClick}
                    sidebarIsOpen={open}
                    dense={dense}
                />
            </SubMenu>
            <SubMenu
                handleToggle={() => handleToggle('menuCatalog')}
                isOpen={state.menuCatalog}
                sidebarIsOpen={open}
                name="pos.menu.catalog"
                icon={<products.icon />}
                dense={dense}
            >
                <MenuItemLink
                    to={`/products`}
                    primaryText={translate(`resources.products.name`, {
                        smart_count: 2,
                    })}
                    leftIcon={<products.icon />}
                    onClick={onMenuClick}
                    sidebarIsOpen={open}
                    dense={dense}
                />
                <MenuItemLink
                    to={`/categories`}
                    primaryText={translate(`resources.categories.name`, {
                        smart_count: 2,
                    })}
                    leftIcon={<categories.icon />}
                    onClick={onMenuClick}
                    sidebarIsOpen={open}
                    dense={dense}
                />
            </SubMenu>
            <SubMenu
                handleToggle={() => handleToggle('menuCustomer')}
                isOpen={state.menuCustomer}
                sidebarIsOpen={open}
                name="pos.menu.customers"
                icon={<visitors.icon />}
                dense={dense}
            >
                <MenuItemLink
                    to={`/customers`}
                    primaryText={translate(`resources.customers.name`, {
                        smart_count: 2,
                    })}
                    leftIcon={<visitors.icon />}
                    onClick={onMenuClick}
                    sidebarIsOpen={open}
                    dense={dense}
                />
                <MenuItemLink
                    to={`/segments`}
                    primaryText={translate(`resources.segments.name`, {
                        smart_count: 2,
                    })}
                    leftIcon={<LabelIcon />}
                    onClick={onMenuClick}
                    sidebarIsOpen={open}
                    dense={dense}
                />
            </SubMenu> */}
            <SubMenu
                handleToggle={() => handleToggle('menuPublicEvent')}
                isOpen={state.menuPublicEvent}
                sidebarIsOpen={open}
                name="Public"
                icon={<privateEvent.icon />}
            >
                <MenuItemLink
                    to={`/users`}
                    primaryText={`User`}
                    sidebarIsOpen={open}
                    dense={dense}
                    onClick={onMenuClick}
                />

                <MenuItemLink
                    to={`/corporates`}
                    primaryText={`Corporates`}
                    sidebarIsOpen={open}
                    dense={dense}
                    onClick={onMenuClick}
                />
            </SubMenu>
            <SubMenu
                handleToggle={() => handleToggle('menuPrivateEvent')}
                isOpen={state.menuPrivateEvent}
                sidebarIsOpen={open}
                name="Private"
                icon={<privateEvent.icon />}
            >
                <MenuItemLink
                    to={`/private-event`}
                    primaryText={`Event`}
                    sidebarIsOpen={open}
                    dense={dense}
                    onClick={onMenuClick}
                />
                <MenuItemLink
                    to={`/private-event-limit`}
                    primaryText={`Event Limit`}
                    sidebarIsOpen={open}
                    dense={dense}
                    onClick={onMenuClick}
                />
                <MenuItemLink
                    to={`/private-event-timeslot-group`}
                    primaryText={`Timeslot Group`}
                    sidebarIsOpen={open}
                    dense={dense}
                    onClick={onMenuClick}
                />
                <MenuItemLink
                    to={`/private-event-timeslot`}
                    primaryText={`Timeslot`}
                    sidebarIsOpen={open}
                    dense={dense}
                    onClick={onMenuClick}
                />
                <MenuItemLink
                    to={`/private-event-invitation`}
                    primaryText={`Event Invitation`}
                    sidebarIsOpen={open}
                    dense={dense}
                    onClick={onMenuClick}
                />
            </SubMenu>
            <MenuItemLink
                to={`/vip`}
                primaryText="VIP Notification"
                leftIcon={<vip.icon />}
                onClick={onMenuClick}
                sidebarIsOpen={open}
                dense={dense}
            />
            {/* {isXsmall && (
                <MenuItemLink
                    to="/configuration"
                    primaryText={translate('pos.configuration')}
                    leftIcon={<SettingsIcon />}
                    onClick={onMenuClick}
                    sidebarIsOpen={open}
                    dense={dense}
                />
            )} */}
            {isXsmall && logout}
        </div>
    );
};

Menu.propTypes = {
    onMenuClick: PropTypes.func,
    logout: PropTypes.object,
};

export default Menu;
