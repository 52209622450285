import * as React from "react";
import { required, Edit, SimpleForm, TextInput, DateInput, DateTimeInput, SelectInput, NumberInput, ReferenceInput, BooleanInput } from 'react-admin';
import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';
import { CustomToolbar } from '../layout';

const Title = ({ record }) => {
    return <span>Corporate {record ? `#${record.id}` : ''}</span>;
};

const useStyles = makeStyles((theme) => ({
    root: {
      flexGrow: 1,
    }
  }));

const CustomForm = () => {
    const classes = useStyles();
    return (
    <div className={classes.root}>
        <Grid container style={{width:"40%"}} spacing={1}>
            <Grid item xs={6}>
                <TextInput source="name" validate={[required()]} fullWidth/>
            </Grid>
            <Grid item xs={6}>
                <TextInput source="type" fullWidth/>
            </Grid>
            <Grid item xs={6}>
                <ReferenceInput label="City" source="city" reference="city" fullWidth>
                    <SelectInput optionText="name" />
                </ReferenceInput>
            </Grid>         
            <Grid item xs={6}>
                <NumberInput source="registration_points" fullWidth/>
            </Grid>
            <TextInput source="domains" fullWidth/>
            <TextInput source="logo" fullWidth />   
            <TextInput source="hash" fullWidth disabled />
        </Grid>
    </div>
)};

const CustomEdit = props => (<Edit title={<Title />} {...props}>
        <SimpleForm  toolbar={ <CustomToolbar /> } warnWhenUnsavedChanges>
            <CustomForm />
        </SimpleForm>
    </Edit>
);

export default CustomEdit;