import * as React from "react";
import { List, Datagrid, TextField, Filter, TextInput, ReferenceField, ReferenceInput, SelectInput, BulkDeleteButton } from 'react-admin';

const CustomFilter = (props) => (
    <Filter {...props}>
        <TextInput label="Email" source="email" alwaysOn />
        <ReferenceInput label="Event" source="event_id" reference="private-event" perPage={999} allowEmpty alwaysOn>
            <SelectInput optionText="name"/>
        </ReferenceInput>
    </Filter>
);

const CustomUrlField = ({ record, source }) => (
    <div>
        <a href={"https://www.onthelist.hk/e/"+record[source]} target="_blank">View</a>&nbsp;&nbsp;&nbsp;&nbsp;
        <a href={"https://www.onthelist.hk/e/"+record[source]+"?send=1"} target="_blank">Resend</a>
    </div>
);

const MyBulkActionButtons = props => (
    <BulkDeleteButton 
        undoable={false} 
        confirmTitle={'Delete invitation'} 
        confirmContent={'Are you sure you want to delete these items?'} 
        {...props} 
    />
);  

const CustomList = props => (
    <List filters={<CustomFilter />} bulkActionButtons={<MyBulkActionButtons />} exporter={false} {...props} title="Private Event Invitation" perPage={25} sort={{ field: 'id', order: 'DESC' }}>
        <Datagrid>
            <TextField source="email" />
            <ReferenceField label="Event" source="event_id" reference="private-event">
                <TextField source="name" />
            </ReferenceField>
            <TextField source="staff_type" />
            <TextField source="phone" />     
            <CustomUrlField source="shorturl" label="Invitation"/> 
        </Datagrid>
    </List>
);

export default CustomList;