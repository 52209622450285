import React from 'react';
import { Admin, Resource, ListGuesser, EditGuesser, ShowGuesser, fetchUtils } from 'react-admin';
import crudProvider from '@fusionworks/ra-data-nest-crud'
import authProvider from './authProvider';
// import { Login } from './layout';

import { Menu } from './layout';
import privateEvent from './privateEvent';
import privateEventLimit from './privateEventLimit';
import privateEventTimeslot from './privateEventTimeslot';
import privateEventTimeslotGroup from './privateEventTimeslotGroup';
import privateEventInvitation from './privateEventInvitation';
import vip from './vip';
import user from './user';
import corporates from './corporates';

const fetchJson = (url, options = {}) => {
  if (localStorage.getItem('auth')) {
    const { access_token } = JSON.parse(localStorage.getItem('auth'));
    options.user = {
      authenticated: true,
      token: `Bearer ${ access_token }`
    };
  }
  return fetchUtils.fetchJson(url, options);
};

const dataProvider = crudProvider('/api',fetchJson);

const App = () => (
  <Admin 
    dataProvider={dataProvider}
    authProvider={authProvider}
    menu={Menu}
    // loginPage={Login}
  >
    <Resource name="private-event" {...privateEvent} />
    <Resource name="private-event-limit" {...privateEventLimit} />
    <Resource name="private-event-timeslot-group" {...privateEventTimeslotGroup} />
    <Resource name="private-event-timeslot" {...privateEventTimeslot} /> 
    <Resource name="users" {...user} create=""/>    
    <Resource name="corporates" {...corporates}/> 
    <Resource name="private-event-invitation" {...privateEventInvitation}/>
    <Resource name="location" />
    <Resource name="city" />      
    <Resource name="vip" {...vip} />
  </Admin>
);
export default App;