import * as React from "react";
import { required, Create, SimpleForm, ReferenceInput, SelectInput, TextInput, BooleanInput, } from 'react-admin';
import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';

const Title = () => {
    return <span>Create Private Event Timeslot Group </span>;
};

const useStyles = makeStyles((theme) => ({
    root: {
      flexGrow: 1,
    }
  }));

const CustomForm = () => {
    const classes = useStyles();
    return (
    <div className={classes.root}>
        <Grid container style={{width:"33.3%"}}>
            <Grid item xs={12}>
            <ReferenceInput source="event_id" reference="private-event" validate={[required()]} fullWidth>
                <SelectInput optionText="name" />
            </ReferenceInput>
            </Grid>
            <Grid item xs={12}>
                <TextInput source="title" validate={[required()]} fullWidth/>
            </Grid>
            <Grid item xs={12}>
                <TextInput source="available_grade" fullWidth/>
            </Grid>
            <Grid item xs={12}>
                <BooleanInput source="reserve_limit"/>
            </Grid>
        </Grid>
    </div>
)};

const CustomCreate = props => (
    <Create title={<Title />} {...props}>
        <SimpleForm>
            <CustomForm />
        </SimpleForm>
    </Create>
);

export default CustomCreate;