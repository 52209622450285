import * as React from "react";
import { Edit, SimpleForm, TextInput, required, ReferenceInput, SelectInput, DateInput, NumberInput } from 'react-admin';
import { CustomToolbar } from '../layout';
import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';

const Title = ({ record }) => {
    return <span>User {record ? `#${record.id}` : ''}</span>;
};

const useStyles = makeStyles((theme) => ({
    root: {
      flexGrow: 1,
    }
  }));

  const CustomForm = () =>{
    const classes = useStyles();
    return (
    <div className={classes.root}>
        <Grid container style={{width:"40%"}} spacing={1}>
            <Grid item xs={6}>
                <TextInput source="first_name" validate={[required()]} fullWidth/>
            </Grid>
            <Grid item xs={6}>
                <TextInput source="last_name" fullWidth/>
            </Grid>
            <Grid item xs={12}>
                <TextInput source="email" validate={[required()]} fullWidth/>
            </Grid>
            <Grid item xs={4}>
                <DateInput source="dob" fullWidth/>
            </Grid>
            <Grid item xs={4}>
                <SelectInput source="gender" fullWidth choices={[
                    { id: 'male', name: 'male' },
                    { id: 'female', name: 'female' },
                    { id: 'other', name: 'other' },
                ]} />
            </Grid>
            <Grid item xs={4}>
                <NumberInput source="phone" fullWidth/>
            </Grid>
            <Grid item xs={4}>
                <NumberInput source="customer_id" label="Customer ID" fullWidth disabled/>
            </Grid>
            <Grid item xs={4}>
                <TextInput source="city" fullWidth/>
            </Grid>
            <Grid item xs={4}>
                <SelectInput source="user_type" fullWidth choices={[
                    { id: 'admin', name: 'admin' },
                    { id: 'user', name: 'user' },
                    { id: 'inactive', name: 'inactive' },
                ]} />
            </Grid>
            <Grid item xs={12}>
                <TextInput source="address_one" multiline fullWidth/>
            </Grid>
            <Grid item xs={6}>
                <TextInput source="remark" fullWidth/>
            </Grid>
            <Grid item xs={6}>
                <ReferenceInput source="corporate_id" reference="corporates" perPage={999} allowEmpty fullWidth>
                    <SelectInput optionText="name"/>
                </ReferenceInput>
            </Grid>
            <Grid item xs={6}>
                <SelectInput source="subscribed_status" fullWidth choices={[
                    { id: 'Subscribed', name: 'Subscribed' },
                    { id: 'Unsubscribed', name: 'Unsubscribed' },
                    { id: 'Cleaned', name: 'Cleaned' },
                    { id: 'NotInMailChimp', name: 'NotInMailChimp' }
                ]} />
            </Grid>
            <Grid item xs={6}>
                <SelectInput source="vip" fullWidth choices={[
                    { id: 'Yes', name: 'Yes' },
                    { id: 'No', name: 'No' }
                ]} />
            </Grid>
        </Grid>
    </div>
)};

const CustomEdit = (props) => (
    <Edit title={<Title />} {...props}>
        <SimpleForm toolbar={<CustomToolbar />} warnWhenUnsavedChanges>
            <CustomForm />
        </SimpleForm>
    </Edit>
);

export default CustomEdit;