import * as React from "react";
import { List, Show, SimpleShowLayout, Datagrid, TextField, RichTextField, NumberField, DateField, BooleanField, Filter, ReferenceField, ReferenceInput, SelectInput, TextInput  } from 'react-admin';
import { ListActions } from '../layout';

const CustomFilter = (props) => (
    <Filter {...props}>
        {/* <TextInput label="Search" source="q" alwaysOn /> */}
        <ReferenceInput label="Event" source="event_id" reference="private-event" perPage={50} alwaysOn>
            <SelectInput optionText="name" />
        </ReferenceInput>
    </Filter>
);

const CustomShow = props => (
    <Show
        {...props}
        /* disable the app title change when shown */
        title=" "
    >
        <SimpleShowLayout>
            <TextField source="child_staff_type" />
            <NumberField source="default_child_no" />
            <TextField source="flashsale_title" />
            <TextField source="policy_page_title" />
            <BooleanField source="show_registration_form" />
            <BooleanField source="show_timeslots" />
            <BooleanField source="show_invitation" />
            <BooleanField source="is_admin_choice" />
            <TextField source="landing_page" />
            <TextField source="checkin_type" />
            <NumberField source="checkin_limit" />
            <TextField source="invitation_method" />
            <TextField source="invitation_template" />
            <TextField source="invitation_subject" />
            <RichTextField source="invitation_html" />
            <TextField source="invitation_merge_tags" />
            <TextField source="shop_now_subject" />
            <DateField source="timeslot_open_time" />
            <TextField source="timeslot_template" />
            <TextField source="timeslot_subject" />
            <RichTextField source="timeslot_html" />
            <TextField source="sms_invitation_template" />
            <TextField source="sms_timeslot_template" />
            <TextField source="staff_type_key" />
            <RichTextField source="policy" />
            <TextField source="flyer_url" />
            <BooleanField source="link_with_public" />
            <TextField source="link_with_public_desc" />
            <TextField source="allowed_domain" />
            <BooleanField source="require_otl_membership" />
            <BooleanField source="show_phone_input" />
            <TextField source="guest_overwrite_level" />
            <BooleanField source="accepted_guest_only" />
            <BooleanField source="enable_two_factor" />
            <NumberField source="max_login_device" />
            <TextField source="utc_timezone" />
            <TextField source="registered_template" />
            <TextField source="custom_field_01" />
            <TextField source="custom_field_02" />
            <TextField source="custom_field_03" />
            <TextField source="custom_field_04" />
            <TextField source="custom_field_05" />
            <TextField source="guest_custom_field_01_label" />
            <TextField source="guest_custom_field_02_label" />
            <TextField source="guest_custom_field_03_label" />
            <TextField source="guest_custom_field_04_label" />
            <TextField source="guest_custom_field_05_label" />
        </SimpleShowLayout>
    </Show>
);


const CustomList = props => (
    <List {...props} filters={<CustomFilter />} bulkActionButtons={false} exporter={false} title="Private Event Limit" perPage={25} sort={{ field: 'id', order: 'DESC' }}>
        <Datagrid rowClick="edit" expand={<CustomShow />}>
            <TextField source="id" />
            <ReferenceField source="event_id" reference="private-event"><TextField source="name" /></ReferenceField>
            <TextField source="staff_type" />
            <TextField source="title" />
            <NumberField source="invitation_limit" />
            <TextField source="invitation_staff_type" />
            <ReferenceField source="default_timeslot_id" reference="private-event-timeslot">
                <TextField source="title" />
            </ReferenceField>
        </Datagrid>
    </List>

);

export default CustomList;